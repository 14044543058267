import { Account } from 'mastodon/models/account';
import { Status } from 'mastodon/models/status';
import { useCallback } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';

type PropsBase = RouteComponentProps &
  Pick<React.HTMLProps<HTMLAnchorElement>, 'className' | 'title' | 'dangerouslySetInnerHTML' | 'children'>;

type AccountLinkProps = PropsBase & { account: Account; /* or Status::Mention */ };
const AccountLinkWithRouter: React.FC<AccountLinkProps> = ({
  account,
  history,
  children,
  ...rest
}) => {
  const onClick = useCallback((e: React.MouseEvent<HTMLAnchorElement>) => {
    if (e.button === 0 && !(e.ctrlKey || e.metaKey) && history) {
      e.preventDefault();
      history.push(`/@${account.get('acct')}`);
    }
  }, []);

  return (
    <a
      href={account.get('url')}
      onClick={onClick}
      {...rest}
    >{children}</a>
  );
};
export const AccountLink = withRouter(AccountLinkWithRouter);

type StatusLinkProps = PropsBase & { status: Status; account?: Account; };
const StatusLinkWithRouter: React.FC<StatusLinkProps> = ({
  status,
  account,
  history,
  children,
  ...rest
}) => {
  const onClick = useCallback((e: React.MouseEvent<HTMLAnchorElement>) => {
    if (e.button === 0 && !(e.ctrlKey || e.metaKey) && history) {
      e.preventDefault();
      const acct = account ? account.get('acct') : status.getIn(['account', 'acct']);
      history.push(`/@${acct}/${status.get('id')}`);
    }
  }, []);

  return (
    <a
      href={status.get('url') as string}
      onClick={onClick}
      {...rest}
    >{children}</a>
  );
};
export const StatusLink = withRouter(StatusLinkWithRouter);
